<template>
    <article class="container my-5 markdown" v-html="content"></article>
</template>

<script>
export default {
    name: 'Content',
    computed: {
        content() {
            let path = this.$route.query.path;
            return require('@a/' + path);
        }
    }
};
</script>